export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(login)": [~7,[2]],
		"/enquiry": [~8,[3]],
		"/enquiry/success": [9,[3]],
		"/orders": [10,[4]],
		"/orders/[id]": [~11,[4]],
		"/reset-password": [~12,[5]],
		"/upload": [~13,[6]],
		"/upload/success": [15,[6]],
		"/upload/[uploadId]": [~14,[6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';